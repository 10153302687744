/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ActivitiesApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get a user&#39;s activites
     * @param user_identity 
     * @param page 
     */
    public list(user_identity?: string, page?: number, __unused?: any): Observable<PagedArray<models.ApiActivity>> {
        const path = '/users/{user_identity}/activities'
                    .replace('{' + 'user_identity' + '}', String(user_identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiActivity>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
