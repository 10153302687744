/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AffiliateMarketingApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get a specific marketing collateral
     * @param id 
     */
    public get(id?: string, __unused?: any): Observable<models.ApiAffiliateMarketingCollateral> {
        const path = '/content/affiliate/marketing/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAffiliateMarketingCollateral>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of marketing collateral items
     * @param offset 
     * @param limit 
     */
    public all(offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAffiliateMarketingCollateral>> {
        const path = '/content/affiliate/marketing';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAffiliateMarketingCollateral>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
