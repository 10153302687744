/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TuneInProvidersApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get the list of tune in providers
     * @param q 
     * @param limit 
     */
    public all(q?: string, limit?: number, __unused?: any): Observable<PagedArray<models.ApiTuneInProvider>> {
        const path = '/content/tune_in_providers';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (q !== undefined) {
            queryParameters['q'] = <any>q;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTuneInProvider>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
