/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class FranchiseApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get a specific franchise
     * @param id 
     */
    public get(id?: string, __unused?: any): Observable<models.ApiFranchise> {
        const path = '/content/franchises/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiFranchise>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a list of franchise by talent id
     * @param host_id 
     */
    public getFranchisesByTalentId(host_id?: string, __unused?: any): Observable<PagedArray<models.ApiFranchise>> {
        const path = '/content/franchises/talent/{host_id}'
                    .replace('{' + 'host_id' + '}', String(host_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFranchise>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of franchises
     */
    public all(__unused?: any): Observable<PagedArray<models.ApiFranchise>> {
        const path = '/content/franchises';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFranchise>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
