/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { NotificationsApi } from './api/NotificationsApi';
import { AffiliateApi } from './api/AffiliateApi';
import { FollowApi } from './api/FollowApi';
import { CommunicationPrefsApi } from './api/CommunicationPrefsApi';
import { UsersApi } from './api/UsersApi';
import { UsernamesApi } from './api/UsernamesApi';
import { PostsApi } from './api/PostsApi';
import { LikesApi } from './api/LikesApi';
import { ActivitiesApi } from './api/ActivitiesApi';
import { ShoutOutsApi } from './api/ShoutOutsApi';
import { BugsApi } from './api/BugsApi';
import { LiveStreamsApi } from './api/LiveStreamsApi';
import { MembershipApi } from './api/MembershipApi';
import { MembershipProductsApi } from './api/MembershipProductsApi';
import { MembershipProductFeaturesApi } from './api/MembershipProductFeaturesApi';
import { OffersApi } from './api/OffersApi';
import { OfferSetsApi } from './api/OfferSetsApi';
import { LogsApi } from './api/LogsApi';
import { LegacyApi } from './api/LegacyApi';
import { GiftsApi } from './api/GiftsApi';
import { SponsorshipProductsApi } from './api/SponsorshipProductsApi';
import { GuestsApi } from './api/GuestsApi';
import { PollsApi } from './api/PollsApi';
import { PetitionsApi } from './api/PetitionsApi';
import { ActblueApi } from './api/ActblueApi';
import { ExternalApi } from './api/ExternalApi';
import { ContributionsApi } from './api/ContributionsApi';
import { CountriesApi } from './api/CountriesApi';
import { ReportingApi } from './api/ReportingApi';
import { ImagesApi } from './api/ImagesApi';
import { ReportCenterApi } from './api/ReportCenterApi';
import { SharesApi } from './api/SharesApi';
import { ImpressionsApi } from './api/ImpressionsApi';
import { CommunityApi } from './api/CommunityApi';
import { WaitingListApi } from './api/WaitingListApi';
import { SlugsApi } from './api/SlugsApi';
import { TermsApi } from './api/TermsApi';
import { HashtagsApi } from './api/HashtagsApi';
import { SearchApi } from './api/SearchApi';
import { RouterApi } from './api/RouterApi';
import { PagesApi } from './api/PagesApi';
import { EmbedApi } from './api/EmbedApi';
import { TuneInProvidersApi } from './api/TuneInProvidersApi';
import { TuneInsApi } from './api/TuneInsApi';
import { ImageAssetsApi } from './api/ImageAssetsApi';
import { ImpactsApi } from './api/ImpactsApi';
import { ModerationApi } from './api/ModerationApi';
import { TopicApi } from './api/TopicApi';
import { PlaybackApi } from './api/PlaybackApi';
import { PressArticlesApi } from './api/PressArticlesApi';
import { NavItemsApi } from './api/NavItemsApi';
import { CareersApi } from './api/CareersApi';
import { CarouselSlidesApi } from './api/CarouselSlidesApi';
import { AnnouncementsApi } from './api/AnnouncementsApi';
import { AffiliateMarketingApi } from './api/AffiliateMarketingApi';
import { ArticlesApi } from './api/ArticlesApi';
import { FranchiseApi } from './api/FranchiseApi';
import { LiveScheduleListsApi } from './api/LiveScheduleListsApi';
import { ProductionsApi } from './api/ProductionsApi';
import { TalentApi } from './api/TalentApi';
import { ShowsApi } from './api/ShowsApi';
import { AppApi } from './api/AppApi';
import { AuditLogsApi } from './api/AuditLogsApi';
import { SessionApi } from './api/SessionApi';
import { CommentsApi } from './api/CommentsApi';
import { BantaApi } from './api/BantaApi';
import { ClientsApi } from './api/ClientsApi';

import { ApiConfiguration } from './configuration';
import { Requester } from './requester';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class ApiModule { 
  /**
   * Create a module which contains the API module's providers.
   * Why: http://stackoverflow.com/questions/39653072/how-to-use-forroot-within-feature-modules-hierarchy
   */
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        NotificationsApi, AffiliateApi, FollowApi, CommunicationPrefsApi, UsersApi, UsernamesApi, PostsApi, LikesApi, ActivitiesApi, ShoutOutsApi, BugsApi, LiveStreamsApi, MembershipApi, MembershipProductsApi, MembershipProductFeaturesApi, OffersApi, OfferSetsApi, LogsApi, LegacyApi, GiftsApi, SponsorshipProductsApi, GuestsApi, PollsApi, PetitionsApi, ActblueApi, ExternalApi, ContributionsApi, CountriesApi, ReportingApi, ImagesApi, ReportCenterApi, SharesApi, ImpressionsApi, CommunityApi, WaitingListApi, SlugsApi, TermsApi, HashtagsApi, SearchApi, RouterApi, PagesApi, EmbedApi, TuneInProvidersApi, TuneInsApi, ImageAssetsApi, ImpactsApi, ModerationApi, TopicApi, PlaybackApi, PressArticlesApi, NavItemsApi, CareersApi, CarouselSlidesApi, AnnouncementsApi, AffiliateMarketingApi, ArticlesApi, FranchiseApi, LiveScheduleListsApi, ProductionsApi, TalentApi, ShowsApi, AppApi, AuditLogsApi, SessionApi, CommentsApi, BantaApi, ClientsApi, 
        ApiConfiguration,
        Requester
      ]
    }
  }
}
