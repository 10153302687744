/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CommunicationPrefsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Update current user communication pref
     * @param id 
     * @param body 
     */
    public updateCommunicationPref(id?: number, body?: models.ApiPostUsersCurrentCommunicationPrefs, __unused?: any): Observable<models.ApiCommunicationPref> {
        const path = '/users/current/communication_prefs/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiCommunicationPref>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Create current user communication pref
     * @param body 
     */
    public createCommunicationPref(body?: models.ApiPostUsersCurrentCommunicationPrefs, __unused?: any): Observable<models.ApiCommunicationPref> {
        const path = '/users/current/communication_prefs';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiCommunicationPref>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Create&#x2F;update list of user communication pref
     * @param body 
     */
    public patchCommunicationPrefs(body?: models.ApiPatchUsersCurrentCommunicationPrefs, __unused?: any): Observable<models.ApiCommunicationPref> {
        const path = '/users/current/communication_prefs';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiCommunicationPref>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get current user communication pref
     * @param device 
     */
    public getCommunicationPrefs(device?: string, __unused?: any): Observable<PagedArray<models.ApiCommunicationPref>> {
        const path = '/users/current/communication_prefs';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (device !== undefined) {
            queryParameters['device'] = <any>device;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiCommunicationPref>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update communication prefs by email
     * @param body 
     */
    public updateCommunicationPrefsByEmail(body?: models.ApiPatchCommunicationPrefs, __unused?: any): Observable<PagedArray<models.ApiCommunicationPref>> {
        const path = '/communication_prefs';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiCommunicationPref>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get communication prefs by email
     * @param body 
     */
    public getCommunicationPrefsByEmail(body?: models.ApiPostCommunicationPrefsCurrent, __unused?: any): Observable<PagedArray<models.ApiCommunicationPref>> {
        const path = '/communication_prefs/current';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiCommunicationPref>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the available communication preferences
     */
    public getAvailableCommunicationPrefs(__unused?: any): Observable<PagedArray<models.ApiCommunicationPref>> {
        const path = '/communication_prefs/available';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiCommunicationPref>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
