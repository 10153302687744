/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CommunityApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Fetch topics from a specific forum
     * @param category_id 
     * @param count 
     * @param page 
     * @param sort 
     */
    public fetchForumTopics(category_id?: string, count?: number, page?: number, sort?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedForumTopic>> {
        const path = '/community/forums/{category_id}'
                    .replace('{' + 'category_id' + '}', String(category_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (count !== undefined) {
            queryParameters['count'] = <any>count;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (sort !== undefined) {
            queryParameters['sort'] = <any>sort;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedForumTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Fetch featured community members
     * @param limit 
     */
    public getFeaturedCommunityMembers(limit?: number, __unused?: any): Observable<PagedArray<models.ApiUserSnippet>> {
        const path = '/community/featured-members';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiUserSnippet>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
