/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PlaybackApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Update the playback session with new playback information
     * @param session_id 
     * @param body 
     */
    public updateSession(session_id?: number, body?: models.ApiPatchContentPlayback, __unused?: any): Observable<models.ApiPlaybackSessionUpdate> {
        const path = '/content/playback/{session_id}'
                    .replace('{' + 'session_id' + '}', String(session_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPlaybackSessionUpdate>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get a specific playback session
     * @param session_id 
     */
    public getSession(session_id?: number, __unused?: any): Observable<models.ApiAbbreviatedPlaybackSession> {
        const path = '/content/playback/{session_id}'
                    .replace('{' + 'session_id' + '}', String(session_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAbbreviatedPlaybackSession>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Start or continue a playback session
     * @param body 
     */
    public startSession(body?: models.ApiPostContentPlaybackStart, __unused?: any): Observable<models.ApiAbbreviatedPlaybackSession> {
        const path = '/content/playback/start';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAbbreviatedPlaybackSession>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get playback status(es)
     * @param body 
     */
    public getSessions(body?: models.ApiPostContentPlaybackQuery, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedPlaybackSession>> {
        const path = '/content/playback/query';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedPlaybackSession>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

}
