/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PollsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Create draft poll&#x2F;petition
     * @param body 
     */
    public createPoll(body?: models.ApiPostPolls, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * List polls&#x2F;petitions
     * @param status 
     * @param type 
     * @param topic 
     * @param page 
     */
    public all(status?: string, type?: string, topic?: string, page?: number, __unused?: any): Observable<PagedArray<models.ApiPoll>> {
        const path = '/polls';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (status !== undefined) {
            queryParameters['status'] = <any>status;
        }

        if (type !== undefined) {
            queryParameters['type'] = <any>type;
        }

        if (topic !== undefined) {
            queryParameters['topic'] = <any>topic;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPoll>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get featured petition
     */
    public getFeaturedPetition(__unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/featured/petition';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get featured poll
     */
    public getFeaturedPoll(__unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/featured';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Sign a petition
     * @param id 
     * @param body 
     */
    public signPetition(id?: string, body?: models.ApiPostPollsIdSign, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}/sign'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * End campaign
     * @param id 
     * @param body 
     */
    public endCampaign(id?: string, body?: models.ApiPostPollsIdEndCampaign, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}/end_campaign'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Vote in a poll
     * @param id 
     * @param body 
     */
    public pollVoteV2(id?: string, body?: models.ApiPostPollsIdVoteV2, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}/vote/v2'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Vote in a poll question (deprecated, use pollVoteV2)
     * @param id 
     * @param body 
     */
    public pollVoteV1(id?: string, body?: models.ApiPostPollsIdVote, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}/vote'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Clear vote in a poll
     * @param id 
     */
    public clearVote(id?: string, __unused?: any): Observable<{}> {
        const path = '/polls/{id}/vote'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Fetch poll&#x2F;petition details
     * @param id 
     */
    public getPoll(id?: string, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update poll&#x2F;petition details
     * @param id 
     * @param body 
     */
    public updatePoll(id?: string, body?: models.ApiPatchPolls, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Publish a poll post and sends email to poll subscribers
     * @param id 
     * @param post_id 
     */
    public postPollsIdPostPostIdPublish(id?: string, post_id?: number, __unused?: any): Observable<{}> {
        const path = '/polls/{id}/post/{post_id}/publish'
                    .replace('{' + 'id' + '}', String(id))
                    .replace('{' + 'post_id' + '}', String(post_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Delete a existing Poll Post
     * @param id 
     * @param post_id 
     */
    public deletePollPost(id?: string, post_id?: number, __unused?: any): Observable<models.ApiPollPost> {
        const path = '/polls/{id}/post/{post_id}'
                    .replace('{' + 'id' + '}', String(id))
                    .replace('{' + 'post_id' + '}', String(post_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPollPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update a existing Poll Post
     * @param id 
     * @param post_id 
     * @param body 
     */
    public updatePollPost(id?: number, post_id?: number, body?: models.ApiPatchPollsIdPost, __unused?: any): Observable<models.ApiPollPost> {
        const path = '/polls/{id}/post/{post_id}'
                    .replace('{' + 'id' + '}', String(id))
                    .replace('{' + 'post_id' + '}', String(post_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPollPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Create a new Poll Post
     * @param id 
     * @param body 
     */
    public createPollPost(id?: number, body?: models.ApiPostPollsIdPost, __unused?: any): Observable<models.ApiPollPost> {
        const path = '/polls/{id}/post'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPollPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Change the poll&#x2F;petition state by triggering an event
     * @param id 
     * @param event_name 
     */
    public triggerPollEvent(id?: string, event_name?: string, __unused?: any): Observable<models.ApiPoll> {
        const path = '/polls/{id}/{event_name}'
                    .replace('{' + 'id' + '}', String(id))
                    .replace('{' + 'event_name' + '}', String(event_name));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPoll>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get recommended polls&#x2F;petitions for the current user
     */
    public getRecommendedPolls(__unused?: any): Observable<PagedArray<models.ApiPoll>> {
        const path = '/recommended-polls';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPoll>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Petitions grouped by Topic
     * @param type 
     * @param per_count 
     */
    public allGroupedByTopic(type?: string, per_count?: number, __unused?: any): Observable<PagedArray<models.ApiTopicGroupedPoll>> {
        const path = '/polls-by-topic';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (type !== undefined) {
            queryParameters['type'] = <any>type;
        }

        if (per_count !== undefined) {
            queryParameters['per_count'] = <any>per_count;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopicGroupedPoll>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
