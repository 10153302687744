/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AffiliateApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * ##DEPRECATED### Get affiliate&#39;s recent activity
     */
    public getActivity(__unused?: any): Observable<PagedArray<models.ApiTAPTransaction>> {
        const path = '/users/current/affiliates/activity';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTAPTransaction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * ##DEPRECATED### Update an affiliate
     * @param body 
     */
    public updateAffiliate(body?: models.ApiPatchUsersCurrentAffiliates, __unused?: any): Observable<models.ApiAffiliate> {
        const path = '/users/current/affiliates';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAffiliate>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * ##DEPRECATED### Create an affiliate
     */
    public createAffiliate(__unused?: any): Observable<models.ApiAffiliate> {
        const path = '/users/current/affiliates';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAffiliate>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a specific announcement
     * @param id 
     */
    public getAnnouncement(id?: string, __unused?: any): Observable<models.ApiAnnouncement> {
        const path = '/content/affiliate/announcements/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAnnouncement>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of announcements
     * @param offset 
     * @param limit 
     */
    public getAnnouncements(offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAffiliateAnnouncement>> {
        const path = '/content/affiliate/announcements';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAffiliateAnnouncement>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * ##DEPRECATED### Get the affiliates leaderboard positions
     * @param period 
     */
    public getLeaderboard(period?: string, __unused?: any): Observable<PagedArray<models.ApiTAPLeaderboard>> {
        const path = '/affiliates/leaderboard';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (period !== undefined) {
            queryParameters['period'] = <any>period;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTAPLeaderboard>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * ##DEPRECATED### Get the affiliate community page data
     */
    public getCommunityPage(__unused?: any): Observable<models.ApiAffiliateCommunityPage> {
        const path = '/affiliates/community';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAffiliateCommunityPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
