/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PagesApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Create a page
     * @param body 
     */
    public createPage(body?: models.ApiPostPages, __unused?: any): Observable<models.ApiPage> {
        const path = '/pages';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the list of pages
     * @param page 
     * @param status 
     * @param search 
     */
    public getPages(page?: number, status?: string, search?: string, __unused?: any): Observable<PagedArray<models.ApiPage>> {
        const path = '/pages';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (status !== undefined) {
            queryParameters['status'] = <any>status;
        }

        if (search !== undefined) {
            queryParameters['search'] = <any>search;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPage>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a specific page
     * @param id 
     * @param editing 
     */
    public getPage(id?: string, editing?: Boolean, __unused?: any): Observable<models.ApiPage> {
        const path = '/pages/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (editing !== undefined) {
            queryParameters['editing'] = <any>editing;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Archive a page
     * @param id 
     */
    public deletePage(id?: string, __unused?: any): Observable<{}> {
        const path = '/pages/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update a page
     * @param id 
     * @param body 
     */
    public updatePage(id?: string, body?: models.ApiPatchPages, __unused?: any): Observable<models.ApiPage> {
        const path = '/pages/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get how to watch entries
     */
    public getHowToWatchPage(__unused?: any): Observable<PagedArray<models.ApiHowToWatchEntry>> {
        const path = '/content/pages/how-to-watch';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiHowToWatchEntry>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get content for the articles page
     */
    public getArticlesPage(__unused?: any): Observable<models.ApiArticlesPage> {
        const path = '/content/pages/articles';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiArticlesPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get content for the community page
     */
    public getCommunityPage(__unused?: any): Observable<models.ApiCommunityPage> {
        const path = '/content/pages/community';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiCommunityPage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get Must See content for the home page
     */
    public getMustSee(__unused?: any): Observable<models.ApiHomeRow> {
        const path = '/content/pages/must_see';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiHomeRow>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get content for the home page
     */
    public getHomePage(__unused?: any): Observable<models.ApiHomePage> {
        const path = '/content/pages/home';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiHomePage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get content based on user&#39;s followed topics
     */
    public getFollowedTopics(__unused?: any): Observable<models.ApiHomePage> {
        const path = '/content/pages/followed-topics';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiHomePage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get content for the topics page
     */
    public getTopicsPage(__unused?: any): Observable<models.ApiHomePage> {
        const path = '/content/pages/topics';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiHomePage>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
