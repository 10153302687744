/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ModerationApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Creates a moderation from Hive with a Reason (will trigger email notification in case is a comment)
     * @param HTTP_AUTHORIZATION 
     * @param action_name 
     * @param reason 
     * @param body 
     */
    public postHiveModerationWithReason(HTTP_AUTHORIZATION?: string, action_name?: number, reason?: number, body?: models.ApiPostContentModerationActionsActionName, __unused?: any): Observable<{}> {
        const path = '/content/moderation/actions/{action_name}/{reason}'
                    .replace('{' + 'action_name' + '}', String(action_name))
                    .replace('{' + 'reason' + '}', String(reason));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Creates a moderation from Hive
     * @param HTTP_AUTHORIZATION 
     * @param action_name 
     * @param body 
     */
    public postHiveModeration(HTTP_AUTHORIZATION?: string, action_name?: number, body?: models.ApiPostContentModerationActions, __unused?: any): Observable<{}> {
        const path = '/content/moderation/actions/{action_name}'
                    .replace('{' + 'action_name' + '}', String(action_name));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get moderation reasons
     */
    public getModerationReasons(__unused?: any): Observable<PagedArray<models.ApiModerationReason>> {
        const path = '/content/moderation/reasons';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiModerationReason>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Returns a list of words to label comments as abusive 
     */
    public getAbuseWords(__unused?: any): Observable<{}> {
        const path = '/content/moderation/banned-words/hidden';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Hides a specific piece of content. A moderation action will be recorded.
     * @param body 
     */
    public hideContentAsStaff(body?: models.ApiPostContentModerationStaff, __unused?: any): Observable<{}> {
        const path = '/content/moderation/staff';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Creates a new moderation report for a given piece of content 
     * @param body 
     */
    public reportContent(body?: models.ApiPostContentModerationReport, __unused?: any): Observable<models.ApiReport> {
        const path = '/content/moderation/report';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiReport>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

}
