/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CommentsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Create a private reply to a comment
     * @param comment_uuid 
     * @param body 
     */
    public createPrivateReply(comment_uuid?: string, body?: models.ApiPostCommentsCommentUuidPrivateReplies, __unused?: any): Observable<models.ApiPrivateReply> {
        const path = '/comments/{comment_uuid}/private-replies'
                    .replace('{' + 'comment_uuid' + '}', String(comment_uuid));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPrivateReply>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

}
