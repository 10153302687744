/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ArticlesApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get a specific article
     * @param id 
     */
    public get(id?: string, __unused?: any): Observable<models.ApiArticle> {
        const path = '/content/articles/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiArticle>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update an article
     * @param id 
     * @param body 
     */
    public update(id?: string, body?: models.ApiPatchContentArticles, __unused?: any): Observable<models.ApiArticle> {
        const path = '/content/articles/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiArticle>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Create an article
     * @param body 
     */
    public create(body?: models.ApiPostContentArticles, __unused?: any): Observable<models.ApiArticle> {
        const path = '/content/articles';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiArticle>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the list of articles
     * @param offset 
     * @param limit 
     * @param status 
     */
    public all(offset?: number, limit?: number, status?: string, __unused?: any): Observable<PagedArray<models.ApiArticle>> {
        const path = '/content/articles';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (status !== undefined) {
            queryParameters['status'] = <any>status;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiArticle>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest articles by franchise id
     * @param franchise_id 
     * @param offset 
     * @param limit 
     * @param status 
     */
    public getArticlesByFranchise(franchise_id?: string, offset?: number, limit?: number, status?: string, __unused?: any): Observable<PagedArray<models.ApiArticle>> {
        const path = '/content/articles/franchise/{franchise_id}'
                    .replace('{' + 'franchise_id' + '}', String(franchise_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (status !== undefined) {
            queryParameters['status'] = <any>status;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiArticle>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get latest articles grouped by franchise
     */
    public getLatestByFranchise(__unused?: any): Observable<PagedArray<models.ApiArticleFranchise>> {
        const path = '/content/articles/by-franchise';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiArticleFranchise>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the most recent featured article
     */
    public getFeaturedArticle(__unused?: any): Observable<models.ApiArticle> {
        const path = '/content/articles/featured';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiArticle>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
