/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class SlugsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Resolve a slug
     * @param slug 
     * @param scope 
     */
    public resolve(slug?: string, scope?: string, __unused?: any): Observable<models.ApiSlug> {
        const path = '/slugs/{slug}'
                    .replace('{' + 'slug' + '}', String(slug));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (scope !== undefined) {
            queryParameters['scope'] = <any>scope;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiSlug>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
