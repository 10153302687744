/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PostsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get the user&#39;s most recent posts
     * @param username 
     * @param page 
     * @param kind 
     * @param user_kind 
     */
    public getUserPosts(username?: string, page?: number, kind?: string, user_kind?: string, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/users/{username}/posts'
                    .replace('{' + 'username' + '}', String(username));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (kind !== undefined) {
            queryParameters['kind'] = <any>kind;
        }

        if (user_kind !== undefined) {
            queryParameters['user_kind'] = <any>user_kind;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get social posts for signed in user
     * @param HTTP_AUTHORIZATION 
     * @param page 
     * @param kind 
     * @param user_kind 
     */
    public getCurrentUserPosts(HTTP_AUTHORIZATION?: string, page?: number, kind?: string, user_kind?: string, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/users/current/posts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (kind !== undefined) {
            queryParameters['kind'] = <any>kind;
        }

        if (user_kind !== undefined) {
            queryParameters['user_kind'] = <any>user_kind;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Create a social post
     * @param body 
     */
    public createPost(body?: models.ApiPostUsersCurrentPosts, __unused?: any): Observable<models.ApiPost> {
        const path = '/users/current/posts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Delete a user post
     * @param id 
     */
    public deletePost(id?: number, __unused?: any): Observable<{}> {
        const path = '/users/current/posts/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Edit a user post
     * @param id 
     * @param body 
     */
    public editPost(id?: number, body?: models.ApiPatchUsersCurrentPosts, __unused?: any): Observable<models.ApiPost> {
        const path = '/users/current/posts/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get curated home recent posts
     * @param after 
     * @param page 
     * @param kind 
     * @param user_kind 
     */
    public getHomePosts(after?: string, page?: number, kind?: string, user_kind?: string, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/posts/home';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (after !== undefined) {
            queryParameters['after'] = <any>after;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (kind !== undefined) {
            queryParameters['kind'] = <any>kind;
        }

        if (user_kind !== undefined) {
            queryParameters['user_kind'] = <any>user_kind;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * List popular posts by topic
     * @param limit 
     */
    public getPopularPostsForFollowingByTopicInterest(limit?: number, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/posts/popular/by_topic';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * List popular posts
     * @param limit 
     */
    public getPopularPostsForFollowing(limit?: number, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/posts/popular';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * When user reposts content
     * @param comment 
     * @param id 
     */
    public repost(comment?: string, id?: number, __unused?: any): Observable<models.ApiPost> {
        const path = '/posts/{id}/repost'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPost>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get post replies
     * @param page 
     * @param id 
     */
    public getReplies(page?: number, id?: number, __unused?: any): Observable<PagedArray<models.ApiPostWithoutParent>> {
        const path = '/posts/{id}/replies'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPostWithoutParent>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a social post
     * @param id 
     */
    public getPost(id?: number, __unused?: any): Observable<models.ApiPostWithReplies> {
        const path = '/posts/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiPostWithReplies>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get community&#39;s most recent posts
     * @param after 
     * @param page 
     * @param kind 
     * @param user_kind 
     */
    public getCommunityPosts(after?: string, page?: number, kind?: string, user_kind?: string, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/community/posts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (after !== undefined) {
            queryParameters['after'] = <any>after;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (kind !== undefined) {
            queryParameters['kind'] = <any>kind;
        }

        if (user_kind !== undefined) {
            queryParameters['user_kind'] = <any>user_kind;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
