/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TopicApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * get feed for the topic
     * @param slug 
     * @param offset 
     * @param limit 
     */
    public getTopicFeed(slug?: string, offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiFullTopicItem>> {
        const path = '/content/topics/{slug}/feed'
                    .replace('{' + 'slug' + '}', String(slug));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFullTopicItem>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a specific topic
     * @param slug 
     */
    public get(slug?: string, __unused?: any): Observable<models.ApiTopic> {
        const path = '/content/topics/{slug}'
                    .replace('{' + 'slug' + '}', String(slug));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiTopic>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of all topics
     */
    public all(__unused?: any): Observable<PagedArray<models.ApiTopic>> {
        const path = '/content/topics';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
