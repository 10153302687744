/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class EmbedApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Fetch a tweet via OEmbed
     * @param url 
     */
    public getTweet(url?: string, __unused?: any): Observable<models.ApiEmbed> {
        const path = '/content/embed/tweet';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (url !== undefined) {
            queryParameters['url'] = <any>url;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiEmbed>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
