/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class SharesApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Create a Share
     * @param body 
     */
    public createShare(body?: models.ApiPostShare, __unused?: any): Observable<models.ApiShare> {
        const path = '/share';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiShare>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

}
