/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class GiftsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get current user&#39;s purchased gift orders
     */
    public getGiftOrders(__unused?: any): Observable<PagedArray<models.ApiGiftOrder>> {
        const path = '/gifts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiGiftOrder>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Purchase a gift
     * @param body 
     */
    public purchase(body?: models.ApiPostGifts, __unused?: any): Observable<models.ApiGiftReceipt> {
        const path = '/gifts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftReceipt>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Send email to remind recipient to redemm their gift
     * @param redemption_code 
     */
    public sendGiftEmailReminder(redemption_code?: string, __unused?: any): Observable<models.ApiGiftItem> {
        const path = '/gifts/redemption/{redemption_code}/reminder'
                    .replace('{' + 'redemption_code' + '}', String(redemption_code));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftItem>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Cancel a gift item
     * @param redemption_code 
     */
    public cancelGiftItem(redemption_code?: string, __unused?: any): Observable<models.ApiGiftItem> {
        const path = '/gifts/redemption/{redemption_code}/cancel'
                    .replace('{' + 'redemption_code' + '}', String(redemption_code));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftItem>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Redeem a gift
     * @param redemption_code 
     * @param body 
     */
    public giftRedemption(redemption_code?: string, body?: models.ApiPostGiftsRedemption, __unused?: any): Observable<models.ApiGiftRedemption> {
        const path = '/gifts/redemption/{redemption_code}'
                    .replace('{' + 'redemption_code' + '}', String(redemption_code));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftRedemption>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get a gift item
     * @param redemption_code 
     */
    public getGiftItem(redemption_code?: string, __unused?: any): Observable<models.ApiGiftItem> {
        const path = '/gifts/redemption/{redemption_code}'
                    .replace('{' + 'redemption_code' + '}', String(redemption_code));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiGiftItem>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
