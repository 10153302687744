/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class HashtagsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get posts list by hashtag name
     * @param page 
     * @param name 
     */
    public fetchPostsByHashtag(page?: number, name?: number, __unused?: any): Observable<PagedArray<models.ApiPost>> {
        const path = '/hashtags/{name}/posts'
                    .replace('{' + 'name' + '}', String(name));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiPost>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get hashtags list
     * @param body 
     */
    public getHashtags(body?: string, __unused?: any): Observable<PagedArray<models.ApiHashtag>> {
        const path = '/hashtags';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiHashtag>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Find a hashtag by name
     * @param name 
     */
    public fetchHashtag(name?: string, __unused?: any): Observable<models.ApiHashtag> {
        const path = '/hashtags/{name}'
                    .replace('{' + 'name' + '}', String(name));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiHashtag>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get hashtags autocomplete list
     * @param body 
     */
    public hashTagAutoComplete(body?: models.ApiPostHashtagsLookupAutocomplete, __unused?: any): Observable<{}> {
        const path = '/hashtags-lookup/autocomplete';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Bulk hashtags lookup
     * @param body 
     */
    public bulkHashtagLookup(body?: models.ApiPostHashtagsLookupBulk, __unused?: any): Observable<PagedArray<models.ApiHashtag>> {
        const path = '/hashtags-lookup/bulk';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiHashtag>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

}
