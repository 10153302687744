/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class FollowApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Create&#x2F;update list of users follow
     * @param body 
     */
    public patchFollows(body?: models.ApiPatchUsersCurrentFollows, __unused?: any): Observable<PagedArray<models.ApiTopic>> {
        const path = '/users/current/follows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Follow&#x2F;unfollow a topic
     * @param body 
     */
    public followTopic(body?: models.ApiPostUsersCurrentFollows, __unused?: any): Observable<PagedArray<models.ApiTopic>> {
        const path = '/users/current/follows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get current users follows
     */
    public getFollows(__unused?: any): Observable<PagedArray<models.ApiFollowedTopic>> {
        const path = '/users/current/follows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFollowedTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Create&#x2F;update list of users followed shows
     * @param body 
     */
    public patchFollowedShows(body?: models.ApiPatchUsersCurrentShows, __unused?: any): Observable<PagedArray<models.ApiTopic>> {
        const path = '/users/current/shows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Follow&#x2F;unfollow a show
     * @param body 
     */
    public followShow(body?: models.ApiPostUsersCurrentShows, __unused?: any): Observable<PagedArray<models.ApiTopic>> {
        const path = '/users/current/shows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiTopic>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get current user&#39;s followed shows
     */
    public getFollowedShows(__unused?: any): Observable<PagedArray<models.ApiFollowedShow>> {
        const path = '/users/current/shows';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFollowedShow>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Unfollow entity
     * @param type 
     * @param identity 
     */
    public unfollowEntity(type?: string, identity?: string, __unused?: any): Observable<{}> {
        const path = '/users/current/followed/{type}/{identity}'
                    .replace('{' + 'type' + '}', String(type))
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Follow an entity
     * @param type 
     * @param identity 
     */
    public followEntity(type?: string, identity?: string, __unused?: any): Observable<models.ApiFollow> {
        const path = '/users/current/followed/{type}/{identity}'
                    .replace('{' + 'type' + '}', String(type))
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Put'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiFollow>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Query for follows in bulk
     * @param body 
     */
    public queryFollows(body?: models.ApiPostUsersCurrentFollowedQuery, __unused?: any): Observable<models.ApiBulkSubjectsQueryBooleanResult> {
        const path = '/users/current/followed/query';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiBulkSubjectsQueryBooleanResult>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get the followers of the user
     * @param identity 
     * @param page 
     */
    public getFollowers(identity?: string, page?: number, __unused?: any): Observable<PagedArray<models.ApiUserSnippet>> {
        const path = '/users/{identity}/followers'
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiUserSnippet>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the followings for the user
     * @param identity 
     * @param type 
     * @param page 
     */
    public getFollowingsForUser(identity?: string, type?: string, page?: number, __unused?: any): Observable<PagedArray<models.ApiFollow>> {
        const path = '/users/{identity}/followed'
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (type !== undefined) {
            queryParameters['type'] = <any>type;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiFollow>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
