/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ProductionsApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Get all productions
     * @param status 
     * @param page 
     * @param editing 
     * @param availability 
     */
    public all(status?: string, page?: number, editing?: Boolean, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (status !== undefined) {
            queryParameters['status'] = <any>status;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }

        if (editing !== undefined) {
            queryParameters['editing'] = <any>editing;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Create a production
     * @param body 
     */
    public create(body?: models.ApiPostContentProductions, __unused?: any): Observable<models.ApiProduction> {
        const path = '/content/productions';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiProduction>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get a specific video&#x2F;podcast production
     * @param id 
     * @param editing 
     */
    public get(id?: string, editing?: Boolean, __unused?: any): Observable<models.ApiAbbreviatedProduction> {
        const path = '/content/productions/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (editing !== undefined) {
            queryParameters['editing'] = <any>editing;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAbbreviatedProduction>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Update a production
     * @param id 
     * @param body 
     */
    public update(id?: string, body?: models.ApiPatchContentProductions, __unused?: any): Observable<models.ApiProduction> {
        const path = '/content/productions/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Patch'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiProduction>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Get an archived production by ID
     * @param id 
     */
    public getArchivedByID(id?: string, __unused?: any): Observable<models.ApiAbbreviatedProduction> {
        const path = '/content/productions/archived/{id}'
                    .replace('{' + 'id' + '}', String(id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiAbbreviatedProduction>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get archived productions for a specific show
     * @param show_id 
     */
    public getArchivedByShow(show_id?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/archived/by-show/{show_id}'
                    .replace('{' + 'show_id' + '}', String(show_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get archived productions for a specific day
     * @param date 
     */
    public getArchivedByDate(date?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/archived/by-day/{date}'
                    .replace('{' + 'date' + '}', String(date));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest Productions by host id
     * @param host_id 
     * @param offset 
     * @param limit 
     */
    public getLatestProductionByHost(host_id?: string, offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/hosts/{host_id}'
                    .replace('{' + 'host_id' + '}', String(host_id));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of candidate VODs
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getCandidateVODs(offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/candidates';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest clips from all shows
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getLatestClips(offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/clips';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest VODs from all shows
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getLatestEpisodes(offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/episodes';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest VODs from all shows [DEPRECATED, use getLatestEpisodes]
     * @param offset 
     * @param limit 
     */
    public getLatestVODs(offset?: number, limit?: number, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/vods';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest audio stories from all shows
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getLatestAudioStories(offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/audio-stories';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get the list of latest podcast from all shows
     * @param offset 
     * @param limit 
     * @param availability 
     */
    public getLatestPodcasts(offset?: number, limit?: number, availability?: string, __unused?: any): Observable<PagedArray<models.ApiAbbreviatedProduction>> {
        const path = '/content/productions/podcasts';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (offset !== undefined) {
            queryParameters['offset'] = <any>offset;
        }

        if (limit !== undefined) {
            queryParameters['limit'] = <any>limit;
        }

        if (availability !== undefined) {
            queryParameters['availability'] = <any>availability;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAbbreviatedProduction>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a list of days in the month and the list of shows that are in it
     * @param date 
     */
    public getShowsInMonth(date?: string, __unused?: any): Observable<{}> {
        const path = '/content/productions/shows-in-month/{date}'
                    .replace('{' + 'date' + '}', String(date));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<any>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a list of available assets, optionally with a search query
     * @param type 
     * @param q 
     * @param page 
     */
    public getVideoAssets(type?: string, q?: string, page?: number, __unused?: any): Observable<PagedArray<models.ApiAvAsset>> {
        const path = '/content/productions/assets';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 
        if (type !== undefined) {
            queryParameters['type'] = <any>type;
        }

        if (q !== undefined) {
            queryParameters['q'] = <any>q;
        }

        if (page !== undefined) {
            queryParameters['page'] = <any>page;
        }


        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<PagedArray<models.ApiAvAsset>>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
