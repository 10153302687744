/**
 * TYT Platform API
 * This is the TYT Platform API. It is used by TYT's apps to interact 
 * with the underlying platform. 
 *
 * NOTE: This file is auto generated. 
 * Do not edit this file manually.
 */


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from '../model/models';
import { Requester } from '../requester';
import { PagedArray } from '../paged-array';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class LikesApi {
    constructor(
        protected requester: Requester
    ) {
    }

    /**
     * Query for likes in bulk
     * @param body 
     */
    public queryLikes(body?: models.ApiPostUsersCurrentLikesQuery, __unused?: any): Observable<models.ApiBulkSubjectsQueryBooleanResult> {
        const path = '/users/current/likes/query';

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Post'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiBulkSubjectsQueryBooleanResult>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
                body,
            );
    }

    /**
     * Unlike a piece of content
     * @param type 
     * @param identity 
     */
    public unlike(type?: string, identity?: string, __unused?: any): Observable<models.ApiLike> {
        const path = '/users/current/likes/{type}/{identity}'
                    .replace('{' + 'type' + '}', String(type))
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Delete'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiLike>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Like a piece of content
     * @param type 
     * @param identity 
     */
    public like(type?: string, identity?: string, __unused?: any): Observable<models.ApiLike> {
        const path = '/users/current/likes/{type}/{identity}'
                    .replace('{' + 'type' + '}', String(type))
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Put'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiLike>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

    /**
     * Get a specific Like
     * @param type 
     * @param identity 
     */
    public getLike(type?: string, identity?: string, __unused?: any): Observable<models.ApiLike> {
        const path = '/users/current/likes/{type}/{identity}'
                    .replace('{' + 'type' + '}', String(type))
                    .replace('{' + 'identity' + '}', String(identity));

        let queryParameters : Record<string,string> = {};
        let headers : Record<string,string> = {}; 

        let requestMethodStr = 'RequestMethod.Get'.replace(/RequestMethod\./, '').toUpperCase();
        let requestPath = path;

        return this.requester
            .request<models.ApiLike>(
                requestMethodStr, 
                requestPath, 
                queryParameters,
                headers,
            );
    }

}
